
import { useMemo } from "react";
import { useAccount, Config, useConnectorClient, useChainId } from "wagmi";

import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { providers } from 'ethers'
import type { Account, Chain, Client, Transport } from 'viem'


export function clientToSigner(client: Client<Transport, Chain, Account>) {
    const { chain, transport }: any = client

    if(!chain){
      return undefined
    }
    const network = {
        chainId: chain?.id,
        name: chain?.name,
        ensAddress: chain?.contracts?.ensRegistry?.address,
    }
    const provider = new providers.Web3Provider(transport, network)
    return provider
}

export function isAddress(value: any): string | false {
    try {
      return getAddress(value)
    } catch {
      return false
    }
  }

export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked()
  }

export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library
  }

export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract  {
    if (!isAddress(address) || address === AddressZero) {
      throw Error(`Invalid 'address' parameter '${address}'.`)
    }
    return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}



// returns null on errors
export function useContract(addresss: any, ABI: any, withSignerIfPossible = true) {
    const { address } = useAccount();
    const chainId = useChainId()
    let account = address
    const { data: client } = useConnectorClient<Config>({ chainId })
    let library: any = client ? clientToSigner(client) : undefined

    return useMemo(() => {
        if (!addresss || !ABI || !library ) return null;
        try {
            return getContract(
                addresss,
                ABI,
                library,
                withSignerIfPossible && account ? account : undefined
            );
        } catch (error) {
            console.error("Failed to get contract", error);
            return null;
        }
    }, [addresss,chainId, ABI, library, withSignerIfPossible, account]);
}


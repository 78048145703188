
import { useEffect } from 'react';
import './style.css'

const Terms = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="container-policy">
                <div className="content-policy">
                    <div className="title1-policy">
                        MeyNetwork Terms of Service
                    </div>

                    <br />
                    <div className="title2-policy">
                        General Terms & Scope
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        This Agreement applies exclusively to MeyNetwork’s website, its subdomains, officially recognized social media channels, and any promotional materials disseminated therein (‘Covered Media’). This Agreement does not apply to, and MeyNetwork expressly disclaims any responsibility or liability for, any unofficial websites, information accessed with an unsecured connection, forums, user-generated content, or third-party media outlets not expressly included within the definition of Covered Media.

                    </div>
                    <br />
                    <div className="dec-policy">
                        By accessing the aforementioned scope, you affirm that you are at least 18 years of age and are thereby agreeing to be bound by these terms of service, as well as all applicable laws and regulations. Further, you acknowledge and accept personal responsibility to comply with any applicable local laws within your jurisdiction. Should you disagree with any of these terms, you are strictly prohibited from using or accessing this site.

                    </div>
                    <br />
                    <div className="dec-policy">
                        The content provided on this website is purely for informational purposes and should never be construed as financial advice. Users should always seek professional financial guidance before making any investment decisions, given the inherent risks involved in investing. MeyNetwork expressly disclaims any and all liability arising from reliance upon such information or any other content presented on the website. Users assume full responsibility for their investment decisions, and MeyNetwork shall not be held liable for any damages, losses, or adverse consequences that may arise from such decisions or any other use of the site.
                    </div>
                    <br />
                    <div className="dec-policy">
                        MeyNetwork will also seek your explicit consent when processing your personal data. Without your consent, access to our website is restricted. For more information on how MeyNetwork uses your data and personal information please refer to our data and privacy policy on the https://mey.network/ website.

                    </div>
                    <br />
                    <div className="dec-policy">
                        All materials and content within this website are protected by applicable copyright and trademark law. Any unauthorized use, reproduction, or distribution of such materials and content is strictly prohibited. MeyNetwork employs metadata and web crawling services to monitor and track the use of its licensed material and intellectual property.

                    </div>
                    <br />
                    <div className="dec-policy">
                        MeyNetwork grants users specific rights as delineated in the Use License described herein below.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Use License

                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍MeyNetwork hereby grants users a limited, revocable license to download a single copy of the materials available on the MeyNetwork website, exclusively for personal, non-commercial, transitory viewing purposes. This privilege may, in specific instances, require prior email registration. Unauthorized reproduction, distribution, or modification of these materials is expressly forbidden and may invite legal ramifications.

                    </div>
                    <br />
                    <div className="dec-policy">
                        This constitutes a licensing agreement and does not represent a transfer of title or ownership rights. While under this license, users are expressly prohibited from engaging in, but not limited to, the following activities:

                    </div>
                    <ul>
                        <li className="dec-policy">
                            Modifying, copying, or reproducing the materials;

                        </li>
                        <li className="dec-policy">
                            Utilizing the materials for any commercial endeavor, or for any public exhibition, be it commercial or non-commercial in nature;
                        </li>
                        <li className="dec-policy">
                            Extracting information from the Website or its associated (sub)domains using web crawlers, bots, or similar digital tools;

                        </li>
                        <li className="dec-policy">
                            Decompiling, reverse engineering, or otherwise attempting to uncover the underlying code of any software featured on the MeyNetwork website(s) or its associated (sub)domains;

                        </li>
                        <li className="dec-policy">
                            Removing, altering, or obscuring any copyright, trademark, or other proprietary rights notices contained within or appended to the materials;

                        </li>
                        <li className="dec-policy">
                            Transferring the materials to another individual or “mirroring” the materials on any alternate server or digital platform;

                        </li>
                    </ul>
                    <br />
                    <br />
                    <div className="dec-policy">
                        Please note that the above list of prohibitions is not exhaustive, and MeyNetwork reserves the right to elucidate further on these restrictions as deemed necessary.
                    </div>
                    <br />
                    <br />
                    <div className="dec-policy">
                        Violation of any of the aforementioned restrictions, whether specifically enumerated or otherwise, shall result in the immediate termination of this license. MeyNetwork further reserves the right to revoke this license at its sole discretion, without any obligation to provide prior notice. Upon the cessation or termination of this licensing agreement, users are compelled to promptly destroy any and all downloaded materials in their possession, irrespective of the format – digital or printed.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Disclaimer
                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍The materials furnished on MeyNetwork’s website are provided ‘as is’ without any representations or warranties of any kind. MeyNetwork expressly disclaims all warranties, both express and implied, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement of proprietary rights. Furthermore, MeyNetwork does not represent or warrant the accuracy, potential outcomes, reliability, or veracity of any materials presented on its website, nor does it make any such representations or warranties in relation to any associated or linked websites.
                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Limitations
                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍In no event shall MeyNetwork or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on MeyNetwork’s website, even if MeyNetwork or a MeyNetwork authorized representative or agent has been notified orally or in writing of the possibility of such damage.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Accuracy of materials

                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍The materials presented on MeyNetwork’s website(s) may contain inaccuracies, including but not limited to technical, typographical, or photographic discrepancies. While MeyNetwork endeavors to furnish content that is both pertinent and timely to the best of its abilities, given the dynamic and evolving nature of the industry, MeyNetwork does not represent or warrant the accuracy, completeness, or contemporaneity of any materials on its site. MeyNetwork retains the unequivocal right to amend, modify, or revise the content of its website(s) at its sole discretion, without any obligation to provide prior notification.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍External links

                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍MeyNetwork disclaims all responsibility and liability for the content, practices, or operations of external websites linked from its site(s). The provision of any such external link by MeyNetwork does not constitute an endorsement or approval of the linked website or its contents. Access to and use of any linked external website is undertaken solely at the user’s own risk and discretion.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Modifications

                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍MeyNetwork reserves the right to modify or amend these Terms of Website Service at its sole discretion, without prior notification. By accessing and utilizing this website, users consent to be governed by the Terms of Website Service as they exist at the time of use.

                    </div>
                    <br />
                    <br />
                    <div className="title2-policy">
                        ‍Governing laws

                    </div>
                    <br />
                    <div className="dec-policy">
                        ‍These Terms of Website Service shall be governed by and interpreted in conformity with the laws of SVG. You unconditionally and irrevocably consent to the exclusive jurisdiction of the courts situated within that territory or jurisdiction.

                    </div>

                </div>
            </div>
        </>
    )
}

export default Terms;
import React, { useState } from "react";
import "./style.scss"
import { useNavigate } from "react-router-dom";

const BannerAirdrop = () => {
    const naigation = useNavigate()
    const [showBanner , setShowBanner] = useState(false)

    const handleShow = () => {
        setShowBanner(true)
    }

    const handleLaunchAir = () => {
        naigation("/airdrop")
    }

    return (
        <>
            {!showBanner &&
                <div className="main-banner-air">
                <div className="container">
                    <div className="wrap-air">
                        <div className="left-row">
                            <div className="text">
                                $MEY IS LIVE! Withdraw your $MEY to your wallet now!
                            </div>
                        </div>
                        <div className="right-row">
                            <div className="group-button-claim">
                                <button className="btn-claim-air" onClick={handleLaunchAir}>
                                    CLAIM NOW
                                </button>
                                <img onClick={handleShow} src="./images/Close-x.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            
        </>
    )
}
export default BannerAirdrop
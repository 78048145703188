
import { useNavigate } from "react-router-dom";
import './style.css'

const ComingSoon = () => {
    const naigation = useNavigate()

    const handleBack = () => {
        naigation("/")
    }
    return (
        <div className="container-com">
            <div className="content-com">
                <div className="title-com">
                    WE ARE LAUNCHING SOON
                </div>
                <div className="text-com">
                    COMING SOON
                </div>
                <div className="com-back" onClick={handleBack} >
                    <img src="/images/left.png" alt="" />
                    <span>Back to Homepage</span>
                </div>
            </div>
        </div>
    )
}

export default ComingSoon;
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';

const ToastNoti = () => {
    return (
        <>
            <ToastContainer
                position="top-right"
                // autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </>
    )
}
export default ToastNoti

import React, { useEffect, useState } from "react";

import { Layout } from "antd";
import Header from "../common/header";
import Footer from "../common/footer";
import ToastNoti from "../Noti";

const { Content } = Layout;

function Main({ children }: any) {


    return (
        <Layout>
            <Header />
            <Content>{children}</Content>
            <Footer />
            <ToastNoti />
        </Layout>
    )
}

export default Main;
import React, { useEffect, useState } from "react";
import { URL_FB, URL_TELE_GROUP, URL_X, URL_YT } from "../../components/contans";
import "./style.scss"
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useContract } from "../../hook/useContract";
import { LoadingOutlined } from '@ant-design/icons';
import Web3 from "web3";
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { AppHome } from "../../api/appHome";
import { Modal, Spin } from "antd";
import abiClaim from "./utils/abiClaim.json"
import { Noti } from "../../components/Noti/toast";

declare global {
    interface Window {
        ethereum: any
    }
}

const AirdropPage = () => {
    const { address } = useAccount();
    const web3: any = new Web3("https://proud-silent-sky.base-mainnet.quiknode.pro/ff3f057f7dec2c8b28e9c15442aa037893b40969/");
    const { open } = useWeb3Modal();
    const [loading, setLoading] = useState(false)
    const [data, setData]: any = useState("")
    const [dataTime, setDataTime]: any = useState("")
    const [checkAir, setCheckAir] = useState(0)
    const [textCheck, setTextCheck]: any = useState()
    const [scPool, setScPool] = useState()
    const contractClaim: any = useContract(scPool && scPool, abiClaim)
    const [amountClaimed, setAmountClaimed]: any = useState(0)
    let timeNowStam = new Date().getTime()
    let timeStart = Number(dataTime?.startTime) * 1000
    let timeEnd = Number(dataTime?.endTime) * 1000
    
    const handleConnect = () => {
        open();
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showDrawer = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const getDataAir = async () => {
        let params = {
            address: address
        }
        try {
            let res = await AppHome.getAirdropUser(params);
            if (res.data.data !== undefined) {
                setDataTime(res.data.data)
                setScPool(res.data.data.claimContract)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    useEffect(() => {
        if (address) {
            getDataAir()
        }
    }, [address]);

    const handleCheckAir = async () => {
        let params = {
            address: address
        }
        try {
            let res = await AppHome.getAirdropUser(params);
            if (res.data.data !== undefined) {
                setCheckAir(res.data.data.claimAmount)
                setIsModalOpen(true)
                setTextCheck(res.data.data.claimAmount)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const handleConfirm = async () => {
        let params = {
            address: address
        }
        try {
            let res = await AppHome.getAirdropUser(params);
            if (res.data.success === true) {
                if (res.data.data !== undefined) {
                    setData(res.data.data);
                    setScPool(res.data.data.claimContract)
                    setIsModalOpen(false)
                }
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    const handleClaim = async () => {
        setLoading(true)
        try {
            if (!data?.claimAmount || !data?.claimSign) {
                Noti('error', "Claim amount or claim signature is missing", 3000)
                throw new Error("Claim amount or claim signature is missing");
            }
            const formattedAmount = data?.claimAmount.toString().replace(',', '.');
            let amountTemp = web3.utils.toWei(formattedAmount, "ether")
            await contractClaim.claimTokens(amountTemp, data?.claimSign)
                .then((res: any) => {
                    res.wait()
                        .then(async (resWait: any) => {
                            if (resWait?.status === 1) {
                                Noti('success', "Claim successfully", 3000)
                                setIsModalOpen(false)
                                setLoading(false)
                            } else {
                                Noti('error', "Unknown error (status hash fail)", 3000)
                                setIsModalOpen(false)
                                setLoading(false)
                            }
                        })
                        .catch((err: any) => {
                            Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                            console.log('err', err)
                            setIsModalOpen(false)
                            setLoading(false)
                        })
                })
                .catch((err: any) => {
                    Noti('error', err?.reason || err?.message || 'Unknown error', 3000)
                    console.log('err', err)
                    setIsModalOpen(false)
                    setLoading(false)
                })
        } catch (error: any) {
            console.log('error', error)
            Noti('error', error?.reason || error?.message || error || 'Unknown error', 3000)
            setLoading(false)
            setIsModalOpen(false)
        }
    }

    const checkClaimed = async () => {
        try {
            await contractClaim.totalClaimed(address).then((res: any) => {
                let amount: any = web3.utils.fromWei(res, "ether");
                setAmountClaimed(Number(amount))
            })
                .catch((err: any) => {
                    console.log('err', err)
                    setAmountClaimed(0)
                })
        } catch (error) {
            console.log('error', error)
            setAmountClaimed(0)
        }
    }

    useEffect(() => {
        if (address && contractClaim) {
            checkClaimed()
        }
    }, [address, contractClaim])

    return (
        <>
            <section className={address ? "section-airdrop-release" : "section-airdrop"}>
                <div className="container">
                    {address ?
                        <>
                            <div className="wrap--page-air-release">
                                <div className="title">
                                    Claim your $MEY
                                </div>
                                <div className="time-page">
                                    <div className="item">
                                        <img src="./images/dots.png" alt="" />
                                        Start Time:
                                        <span>
                                            {dataTime?.startTimeUi}
                                        </span>
                                    </div>
                                    <div className="item">
                                        <img src="./images/dots.png" alt="" />
                                        End Time :
                                        <span>
                                            {dataTime?.endTimeUi}
                                        </span>
                                    </div>
                                </div>
                                <div className="box-claim-page">
                                    <div className="left-claim">
                                        <div className="wave-content">
                                            <div className="res-top">
                                                <div className="fluw">
                                                    <div className="txt-top">
                                                        Your Total Reward
                                                    </div>
                                                    <div className="txt-bottom">
                                                        {data?.claimAmount ? new Intl.NumberFormat('en-US').format(data?.claimAmount) : "--"} $MEY
                                                    </div>
                                                </div>
                                                <div className="fluw">
                                                    <div className="txt-top">
                                                        Claimed
                                                    </div>
                                                    <div className="txt-bottom">
                                                        {amountClaimed ? new Intl.NumberFormat('en-US').format(amountClaimed) : "--"} $MEY
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="res-bottom">
                                                <div className="text">
                                                    Claimable Token
                                                </div>
                                                <div className="number-mey">
                                                    {data?.claimAmount ? new Intl.NumberFormat('en-US').format(data?.claimAmount) : "--"} $MEY
                                                </div>
                                            </div>
                                            {textCheck === 0 ?
                                                <>
                                                    <div className="text-err">
                                                        <img src="./images/wa.png" alt="" /> This address is not eligible.
                                                    </div>
                                                </>
                                                :
                                                <>
                                                    {""}
                                                </>
                                            }
                                            {data?.claimAmount > 0 ?
                                                <>
                                                    {timeNowStam < timeStart || timeNowStam > timeEnd ?
                                                        <>
                                                            <div className="connect-wallet">
                                                                <button className="btn btn-connect res dis">
                                                                    Upcoming
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            {loading ?
                                                                <>
                                                                    <div className="connect-wallet">
                                                                        <button className="btn btn-connect dis res">
                                                                            Claim All <Spin size="large" indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} />
                                                                        </button>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {Number(amountClaimed) > 0 ?
                                                                        <>
                                                                            <div className="connect-wallet">
                                                                                <button className="btn btn-connect dis res">
                                                                                    Claimed
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className="connect-wallet">
                                                                                <button className="btn btn-connect res" onClick={handleClaim}>
                                                                                    Claim All
                                                                                </button>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                </>
                                                            }

                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {textCheck === 0 ?
                                                        <>
                                                            <div className="connect-wallet">
                                                                <button className="btn btn-connect res">
                                                                    Try another Wallet
                                                                </button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="connect-wallet">
                                                                <button className="btn btn-connect res" onClick={handleCheckAir}>
                                                                    Check Eligibility
                                                                </button>
                                                            </div>
                                                        </>
                                                    }

                                                </>
                                            }
                                        </div>
                                    </div>
                                    <div className="right-claim">
                                        <div className="box-imgs">
                                            <img src="./images/img-ww.png" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="wrap-page-air">
                                <div className="box-img">
                                    <img src="./images/img-air-page.png" alt="" />
                                </div>
                                <div className="title">
                                    Claim your $MEY
                                </div>
                                <div className="desc">
                                    It's time! Unlock your $MEY
                                </div>
                                <button className="connect-btn" onClick={handleConnect}>
                                    Connect Wallet
                                </button>
                                <div className="join-network">
                                    <div className="text">
                                        Join Our Community
                                    </div>
                                    <div className="list-social">
                                        <a className='link-text' target='_blank' href={URL_X}>
                                            <img src="./images/x-footer.png" alt="" />
                                        </a>
                                        <a className='link-text' target='_blank' href={URL_TELE_GROUP}>
                                            <img src="./images/telegram-footer.png" alt="" />
                                        </a>
                                        <a className='link-text' target='_blank' href={URL_YT}>
                                            <img src="./images/youtube-footer.png" alt="" />
                                        </a>
                                        <a className='link-text' target='_blank' href={URL_FB}>
                                            <img src="./images/facebook-footer.png" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {address ?
                        <>
                            <div className="how-work-it">
                                <div className="title">
                                    How it work?
                                </div>
                                <div className="wrap-work">
                                    <div className="item">
                                        <div className="top-nest">
                                            <div className="box-img-res">
                                                <img src="./images/w-1.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Step 1
                                            </div>
                                        </div>
                                        <div className="bottom-nest">
                                            Connect Your Wallet
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="top-nest">
                                            <div className="box-img-res">
                                                <img src="./images/w-2.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Step 2
                                            </div>
                                        </div>
                                        <div className="bottom-nest">
                                            Check Your Eligibility
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="top-nest">
                                            <div className="box-img-res">
                                                <img src="./images/w-3.png" alt="" />
                                            </div>
                                            <div className="text">
                                                Step 3
                                            </div>
                                        </div>
                                        <div className="bottom-nest">
                                            Claim Token Airdrop
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            {""}
                        </>
                    }
                </div>
            </section>
            <Modal className="modal-dis" title="Check Eligibility" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {checkAir > 0 ?
                    <>
                        <div className="airdrop-modal">
                            <div className="box-img">
                                <img src="./images/air-success.png" alt="" />
                            </div>
                            <div className="title-success">
                                You are eligible!
                            </div>
                            <div className="address-user">
                                {address?.slice(0, 4)}...{address?.slice(-4)}
                            </div>
                            <div className="connect-wallet">
                                <button className="btn btn-connect res" onClick={handleConfirm}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="airdrop-modal">
                            <div className="box-img">
                                <img src="./images/air-err.png" alt="" />
                            </div>
                            <div className="title-success err">
                                Sorry this address is not eligible
                            </div>
                            <div className="address-user">
                                {address?.slice(0, 4)}...{address?.slice(-4)}
                            </div>
                            <div className="connect-wallet">
                                <button className="btn btn-connect res" onClick={handleOk}>
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </>
                }
            </Modal>
        </>
    )
}
export default AirdropPage
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";

function getUrl(config: any) {
    if (config.baseURL) {
        return config.url.replace(config.baseURL, "").split("?")[0];
    }
    return config.url;
}

export const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
});

instance.interceptors.request.use(
    async (config) => {
        //@ts-ignore
        // console.log(`%c ${config.method.toUpperCase()} - ${config.url}:`, 'color: #0086b3; font-weight: bold', config);

        return config;
    },
    (error) => {
        // console.log(`%c ${error.response.status}  :`, 'color: red; font-weight: bold', error.response.data);
        return Promise.reject(error);
    }
);

const checkResponse = (error: any) => {
    switch (error.response.status) {
        case 403:
            break;
        case 400:
            console.log(error.response.message);
            break;
        default:
            console.log(
                `%c ${error.response.status}  :`,
                "color: red; font-weight: bold",
                error.response.data
            );
    }
};

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    function (error) {
        if (error.response) {
            return Promise.reject({
                status: error.response.status,
                message: error.response.data.message,
            });
        } else if (error.request) {
            return Promise.reject(error.request);
        } else {
            return Promise.reject(error);
        }
    }
);

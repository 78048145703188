import React from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import './App.css';
import HomePage from './pages/home-page';
import Main from './components/main';
import ComingSoon from './components/comingsoon';
import Policy from './pages/polycy';
import Terms from './pages/terms';
import AirdropPage from './pages/airdrop-page';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { base, baseSepolia } from "wagmi/chains";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";

function App() {
    const queryClient = new QueryClient();
    const projectId = "5f6c18132c6e21af2b276b34ad0af7ec";
    const metadata = {
        name: "Web3Modal",
        description: "Web3Modal",
        url: "https://web3modal.com",
        icons: ["https://avatars.githubusercontent.com/u/37784886"],
    };

    const chains = [base] as const;

    const config = defaultWagmiConfig({
        chains,
        projectId,
        metadata,
        enableInjected: false,
        enableWalletConnect: true,
        enableEIP6963: true,
        enableCoinbase: false,
    });
    createWeb3Modal({
        wagmiConfig: config,
        projectId,
        enableAnalytics: true,
    });

    return (
        <div className="App">
            <WagmiProvider config={config}>
                <QueryClientProvider client={queryClient}>
                    <Main>
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/PrivacyPolicy" element={<Policy />} />
                            <Route path="/Term" element={<Terms />} />
                            <Route path="/airdrop" element={<AirdropPage />} />
                            <Route path="/coming-soon" element={<ComingSoon />} />

                            <Route path="*" element={<Navigate to="/" replace />} />
                        </Routes>
                    </Main>
                </QueryClientProvider>
            </WagmiProvider>
        </div>
    );
}

export default App;

import { toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Noti = (type :any, content :any, duration :any)=>{
    switch (type) {
        case 'success':
                toast.success(`${content || 'Unknown'}`, {
                        position: "top-right",
                        autoClose: duration || 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    })
                break;
        case 'info':
                toast.info(`${content || 'Unknown'}`, {
                        position: "top-right",
                        autoClose: duration || 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    })
                break;
        case 'warn':
                toast.warn(`${content || 'Unknown'}`, {
                        position: "top-right",
                        autoClose: duration || 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    })
                break;
        case 'error':
                toast.error(`${content || 'Unknown'} `, {
                        position: "top-right",
                        autoClose: duration || 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    })
                break;
    
        default:
                toast.error(`${content || 'Unknown'}`, {
                        position: "top-right",
                        autoClose: duration || 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light"
                    })
            break;
    }

}